import React from 'react';
import SEO from '../components/SEO';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import PageBlog from '../container/BlogGrid/PageBlog';
// import CallToAction from '../container/CallToAction/CallToAction';
// import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const BlogGrid = () => {
    return (
        <React.Fragment>
            <SEO title="Intentive Software Solutions" />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-two.jpg"
                title="Join the journey with us !"
                // content="Home"
                // contentTwo="Careers"
            />
            <PageBlog />
            {/* <CallToAction /> */}
            {/* <Footer /> */}
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BlogGrid;

import React from 'react';
import SEO from '../components/SEO';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ScrollToTop from '../components/ScrollToTop.jsx';
import JobApply from '../container/ContactFromContainer/JobApply';

const JobForm = () => {
    return (
        <React.Fragment>
            <SEO title="Intentive Software Solutions" />
            {/* <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.jpg"
                title="Some text that match with the context"
                // content="Home"
                // contentTwo="Contact Us"
            /> */}
            {/* <ContactInformation />
            <GoogleMap /> */}
            <JobApply />
            {/* <Footer /> */}
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.jpg"
                title="Let's build a future together"
                // content="Home"
                // contentTwo="Contact Us"
            />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default JobForm;

import { useState, useEffect, useRef } from "react";
import SectionTitle from "../SectionTitles/SectionTitle";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
// import { Link } from "react-router-dom";

const HomeAbout = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div
      id="about"
      className="section section-padding-t90 section-padding-bottom-200"
    >
      <div className="container">
        <SectionTitle
          title="Harnessing the Future of Technology"
          subTitle="We started Intentive because we saw a gap in the market: businesses needed reliable, high-quality software development without the sky-high costs. Based in Canada, we partner with a talented team to deliver solutions that help our clients succeed. We're here to do great work, solve problems, and yes, make a profit while we're at it."
        />

        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/about/home-one-about/home_agency_about_1.jpg"
                    }
                    alt=""
                  />
                </Tilt>
              </div>

              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/about/home-one-about/home_agency_about_2.jpg"
                    }
                    alt=""
                  />
                </Tilt>
              </div>
              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/shape-animation/about-shape-1.png"
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle="Innovation Drives Us Forward"
                title="Empowering Businesses with Expertise and Vision"
              />

              <p>
                We specialize in delivering comprehensive solutions, from Web and Mobile Development to IT Consulting and Product Design. By partnering closely with our clients, we ensure every project is tailored to meet their unique needs, leading to shared success and ongoing innovation.
              </p>

              {/* <Link
                className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4"
                to={process.env.PUBLIC_URL + "/about"}
              >
                Contact Us
              </Link> */}
              <a href={`#services`}>
                <span className="btn btn-2 btn-custom mt-4">Services</span>
              </a>
              <a href={`#contact`}>
                <span className="btn btn-2 btn-custom-outline mt-4 ml-2">
                  Contact Us
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
